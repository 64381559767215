import Config from "@/vendor/config"
import Request from "@/api/request.js"

const getAll = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/plate/index', params)
}

const getOne = districtId => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/district/one', {
		'district_id': districtId
	})
}

export default {
	getAll,
	getOne
}
