<template>
	<div class="toggle">
		<loading-page :loading="loadingPage"></loading-page>
		<div class="recent">
			<div class="department-list">
				<w-title title="最近访问"></w-title>
				<div class="department-item" @click="$router.back()">
					<div class="department-item-left">
						<img :src="currentDistrict.logo" />
					</div>
					<div class="department-item-right">
						<div class="department-item-title">{{ currentDistrict.name }}</div>
						<div class="department-item-count">
							平安义警总人数：{{ currentDistrict.total ? currentDistrict.total : 0 }}人
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="department">
			<div class="department-list">
				<w-title title="全部平台"></w-title>
				<div class="department-item" v-for="vo in districtList" @click="onSelectDistrict(vo)">
					<div class="department-item-left">
						<img :src="vo.logo" />
					</div>
					<div class="department-item-right">
						<div class="department-item-title">{{ vo.name }}</div>
						<div class="department-item-count">平安义警总人数：{{ vo.total ? vo.total : 0 }}人</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VantVendor from '@/vendor/vant';
import DistrictApi from '@/api/district';
import DistrictHelper from '@/vendor/district';
import WTitle from '@/components/Title';
import LoadingPage from '@/components/LoadingPage';
import UserApi from '@/api/user';
export default {
	data() {
		return {
			loadingPage: true,
			currentDistrict: {},
			districtList: []
		};
	},
	created() {
		DistrictApi.getAll()
			.then(result => {
				this.districtList = result.data.list;
				this.currentDistrict = result.data.recent
				this.loadingPage = false;
				// this.currentDistrict = this.findCurrentDistrict();
			})
			.catch(error => {
				console.log(error);
			});
			
		// 如果传了返回参数，则立即返回首页
		if(this.$route.query.toBack) {
			this.toHome()
		}
	},
	methods: {
		findCurrentDistrict() {
			let districtId = DistrictHelper.getDistrictId();

			let index = this.districtList.findIndex(e => {
				return e.id === districtId;
			});
			return this.districtList[index];
		},
		onSelectDistrict(district) {
			
			UserApi.loginLog();
			
			VantVendor.Toast.loading({
				message: '加载中',
				duration: 100,
				transition: 'none',
				loadingType: 'spinner'
			});
			
			if(district.id == 2){
				return window.location.replace('http://xzyjv2.web.zyrlzy.cn/#/');
			}
	
			document.title = district.name + (district.id == 1 ? '平安义警协会' : '平安义警分会');
			
			this.$store.commit('district/setDistrict', district);
			
			this.toHome()
		},
		toHome() {
			this.$store.commit('keep/clearKeep');
			this.$nextTick(() => {
				this.$router.replace({ name: 'Index' });
			})
		}
	},
	components: {
		WTitle,
		LoadingPage
	}
};
</script>

<style lang="less" scoped>
.toggle {
	padding: 20px 10px;

	/deep/.title .text {
		color: #333;
	}

	.recent {
		padding-bottom: 20px;
	}

	.department-list {
		.department-item {
			margin-top: 10px;
			padding: 10px;
			background-color: #fff;
			border-radius: 6px;
			display: flex;
			align-items: center;

			.department-item-left {
				width: 60px;
				height: 60px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					display: block;
					border-radius: 100%;
				}
			}

			.department-item-right {
				flex: 1;
				padding-left: 5px;
				padding-right: 22px;
				background-image: url('../../assets/img/icon9.png');
				background-position: right center;
				background-size: 14px;
				background-repeat: no-repeat;

				.department-item-title {
					font-size: 18px;
					line-height: 26px;
					color: #333;
					padding-bottom: 5px;
				}

				.department-item-count {
					font-size: 14px;
					color: #666;
					line-height: 20px;
				}
			}
		}
	}
}
</style>
